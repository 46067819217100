import React from "react";
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet
} from "react-router-dom";
import Home from "./home/Home";


function App() {
  return (
    <div className="App">
      <Router>

        <Routes>
          <Route path="/" element={<Home />} />


        </Routes>
      </Router>
 
    </div>
  );
}

export default App;
