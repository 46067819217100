import React from 'react';
import classes from "./Home.module.css";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HeaderImg from '../../img/Mockup2.jpg'
import Fade from 'react-reveal/Fade';
function createData(name, per) {
    return { name, per };
}


const rows = [
    createData('Potassium ', '< 0.1'),
    createData('Sodium', ' 8.5'),
    createData('Calcium', '9.5'),
    createData('Magnesium', '1.2'),
    createData('Sulphate', '8.0'),
    createData('Chloride', '15.7'),
    createData('Alkalinity', '38.2'),
    createData('Nitrate', '< 0.18'),
    createData('Fluoride', ' < 0.2'),
    createData('Ph', '8.2'),
];


export default function Products() {

    return (
        <div className={classes.productContainer} id="Products">
            <Fade bottom>
                <Row >
                    <Col sm={8}>
                        <h1 className={classes.subHeader}>Nutrition Facts</h1>
                        <div>

                            <TableContainer className={classes.factTable} >
                                <Table size='small'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell> <b>Typical Mineral Composition in Mg/Litre</b></TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <TableRow
                                                key={row.name}                                >
                                                <TableCell>
                                                    <b>{row.name}</b>
                                                </TableCell>
                                                <TableCell align='right' ><b>{row.per}</b></TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </div>

                    </Col>
                    <Col sm={4}>

                        <img src={HeaderImg} className={classes.headerImg}></img>

                    </Col>
                </Row>
            </Fade>
        </div>
    )
}

