
import React from 'react';

import classes from "./Home.module.css";
import { Card } from "react-bootstrap";
import { CardGroup } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Mockup3 from '../../img/Mockup3.jpg'
import HeadShake from 'react-reveal/HeadShake';
import Fade from 'react-reveal/Fade';

export default function AboutUs() {
    return (
        <div className={classes.aboutContainer} id="About">
     
                <Fade bottom>
                    <Row>

                        <Col sm={4}>
                            <img src={Mockup3} className={classes.aboutImg}></img>
                        </Col>
                        <Col sm={8}>
                            <Card className={classes.cardStyle}  >
                                <Card.Body>
                                    <Card.Title className={classes.subHeader} >About Us</Card.Title>

                                    <Card.Text className={classes.cardTxt}>
                                        <p>All of our water products are produced in store.This purification process
                                            is carried out using only the best. Get the most out of every day with the pure, crisp taste of water. <br />
                                            Our product combines the process of reverse osmosis filtration with
                                            a proprietary blend of minerals to create fresh, clean, and premium <br />
                                            tasting water that is pure and delicious.
                                        </p>
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>
                </Fade>
   
        </div>
    )
}

