import React from 'react';
import classes from "./Home.module.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Card } from "react-bootstrap";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';


export default function ContactUs() {
    return (
        <div style={{textAlign:"center"}}>
            <div className={classes.footer} id="Contact">

                <Container fluid>
                    <h1 className={classes.subHeader} style={{ paddingTop: "30px",color:"white"}} >Contact Us</h1>
                    <Row className={classes.contactRow} >
                        <a className={classes.contactP} href="tel:0216910105"> <LocalPhoneIcon />&nbsp;  021 691 0105 </a>
                        <a className={classes.contactP} href="mailto:springforesttissues@gmail.com"> <EmailIcon /> &nbsp;  springforesttissues@gmail.com</a>
                        <a className={classes.contactP} style={{ marginBottom: "30px" }} href="https://goo.gl/maps/L8czv3WQfarH6Y4m8">
                            <LocationOnIcon />&nbsp; Athlone Industrial 2, Unit 4 Silica Road</a>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26468.09174463129!2d18.512490272075784!3d-33.97939409832673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc44a474b36e01%3A0x21ef5aad7869db7e!2sIndustrial%20Area%20-%20Athlone%20Industrial!5e0!3m2!1sen!2sza!4v1655238042710!5m2!1sen!2sza"
                            style={{ width: "100%", height: "300px", borderRadius: "30px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='Athlone Industrial 2, Unit 4 Silica Road<'></iframe>

                    </Row>

                </Container>
               
            </div>
            <p style={{ background: "#65a5e0c9", color: "white",marginBottom:"0px" }}>  Copyright ©
                    <script>document.write(new Date().getFullYear());</script>
                    2022 All rights reserved | Designed By
                    <a title="Sology Group" href="http://sology.net" target="_blank" style={{ color: "white" }}>
                   <img src="https://autospecsms.co.za/images/sology.png" style={{ height: "20px", paddingLeft: "3px" }}></img></a>
                </p>
        </div>
    )
}

