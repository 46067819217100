import React from "react";
import classes from "./Home.module.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from 'react-reveal/Fade';
import './header.css'


export default function Header() {

    return (


        <div class="wrapper" id="Home">
        <Fade bottom>
   
            <h1 className={classes.title}>Welcome to Nada Water Company</h1>
            <p className={classes.subTitle}>All of our water products are produced in store.This purification process
                is carried out using only the best. Get the most out of every day with the pure, crisp taste of water. <br />
                Our product combines the process of reverse osmosis filtration with
                a proprietary blend of minerals to create fresh, clean, and premium <br />
                tasting water that is pure and delicious.
            </p>
            </Fade>
            <h1 className={classes.breakBottom} ></h1>
            <div><span class="dot"></span></div>
            <div><span class="dot"></span></div>
            <div><span class="dot"></span></div>
            <div><span class="dot"></span></div>
            <div><span class="dot"></span></div>
            <div><span class="dot"></span></div>
            <div><span class="dot"></span></div>
            <div><span class="dot"></span></div>
            <div><span class="dot"></span></div>
            <div><span class="dot"></span></div>
            <div><span class="dot"></span></div>
            <div><span class="dot"></span></div>
            <div><span class="dot"></span></div>
            <div><span class="dot"></span></div>
            <div><span class="dot"></span></div>
        </div>



    )

}