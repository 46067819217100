import * as React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { Nav } from 'react-bootstrap';
import { NavDropdown } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import OpacityIcon from '@mui/icons-material/Opacity';
import { ClassNames } from '@emotion/react';
import classes from "./Home.module.css";
import Logo from "../../img/nada-logo.png";

export default function NavbarBar() {
    return (
        <Navbar collapseOnSelect expand="lg"  sticky="top" className={classes.navStyle} >
            <Container>
                <Navbar.Brand href="#Home" className={classes.brandStyle}>
              <img src={Logo} style={{width:"120px"}}></img>
              </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
           
                    <Nav>
                        <Nav.Link href="#Home"  style={{color:"#0a6fc3", marginRight:"20px"}}> Home </Nav.Link>
                        <Nav.Link href="#Products"  style={{color:"#0a6fc3", marginRight:"20px"}}> Our Products </Nav.Link>
                        <Nav.Link href="#About"  style={{color:"#0a6fc3", marginRight:"20px"}}> About Us </Nav.Link>
                        <Nav.Link href="#Contact"  style={{color:"#0a6fc3", marginRight:"20px"}}>   Contact </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
